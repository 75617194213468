import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { sendMessage } from "../../../api/telegram";
import CustomInput from "../CustomInput/CustomInput";
import "./Modal.css"; // Создайте файл стилей Modal.css
import CustomTextArea from "../CustomTextArea/CustomTextArea";

const Modal = ({ isOpen, onClose, children }) => {
  const [isBrowser, setIsBrowser] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerText, setCustomerText] = useState("");
  const [nameBlur, setNameBlur] = useState(false);
  const [numberBlur, setNumberBlur] = useState(false);
  const [textBlur, setTextBlur] = useState(false);
 
  const [modalStatus, setModalStatus] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const handleSendMessage = async () => {
    if (customerName !== "" && customerEmail !== "" && customerText !== "") {
      const requestStatus = await sendMessage(
        `Кто-то оформил заказ на сайте: Имя: ${customerName}, Номер телофона:${customerEmail} , Название изделия для пошива: ${customerText}`
     )
    if (requestStatus) {
      setModalStatus(true);
    }          
      setCustomerEmail("");
      setCustomerName("");
      setNameBlur(false);
      setNumberBlur(false);
    } else {
      setNameBlur(true);
      setNumberBlur(true);
    }
  };

  const modalContent = isOpen && (
    <div className="modal-overlay">
      <div className="modal">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        {!modalStatus ? (
          <>
            {" "}
            <p className="title">Связаться</p>
            <p className="description">
              Оставьте заявку и наши специалисты свяжутся с вами в ближайшее
              время
            </p>
            <CustomInput
              blur={nameBlur}
              setBlur={setNameBlur}
              placeholder="*Фамилия и имя"
              value={customerName}
              onChange={setCustomerName}
            />
            <CustomInput
              blur={numberBlur}
              setBlur={setNumberBlur}
              placeholder="*Номер телефона"
              value={customerEmail}
              onChange={setCustomerEmail}
            />
            <CustomTextArea
              blur={textBlur}
              setBlur={setTextBlur}
              placeholder="*Название изделия для пошива"
              value={customerText}
              onChange={setCustomerText}
            />
            <p className="rules">
              Оформляя заказ, вы выражаете свое согласие на обработку
              персональных данных в соответствии с Политикой конфиденциальности.
            </p>
            <button onClick={handleSendMessage} className="button">
              ОФОРМИТЬ ЗАКАЗ
            </button>
          </>
        ) : (
          <>
            <p className="succesText">Ваша заявка успешно офермлена</p>
          </>
        )}
      </div>
    </div>
  );

  return isBrowser ? ReactDOM.createPortal(modalContent, document.body) : null;
};

export default Modal;
