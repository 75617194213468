import styles from "./AboutUs.module.css";
const AboutUs = ({ openModal }) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.form}>
            <p className={styles.title}>
              МЫ ОТШИВАЕМ ПОД ВАШ БРЕНД И ПО ВАШИМ МОДЕЛЯМ
            </p>
            <p className={styles.description}>
              Для получения более детальной информации свяжитесь с нашими
              менеджерами.
            </p>
            <p onClick={openModal} className={styles.button}>
              СВЯЗАТЬСЯ
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
