import styles from "./MenuContainer.module.css";

const MenuContainer = ({ isMenuOpen, setMenuOpen }) => {
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setMenuOpen(false);
    }
  };

  return (
    <div className={styles.menuContainer}>
      <div
        className={`${styles.menu} ${
          isMenuOpen ? styles.menuOpen : styles.menuClose
        }`}
      >
        <p onClick={toggleMenu} className={styles.menuButton}>
          &times;
        </p>
        <ul className={styles.menuList}>
          <li onClick={() => scrollToElement("main")}>Главная</li>
          <li onClick={() => scrollToElement("clothes")}>Одежды</li>
          <li onClick={() => scrollToElement("about-us")}>О нас</li>
          <li onClick={() => scrollToElement("advantage")}>
            Наше Преимущество
          </li>
          <li onClick={() => scrollToElement("instagram")}>Наш инстаграм</li>
          <li onClick={() => scrollToElement("marketplaces")}>Маркетплейсы</li>
        </ul>
      </div>
    </div>
  );
};

export default MenuContainer;
