import styles from "./Whatsapp.module.css";
import whatsappIcon from "../../../Assests/Icons/whatsappIcon.svg";
import { whatsappLink } from "../../../Utils/Constants/Constants.js";

function Whatsapp() {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.pulse}>
          <a href={whatsappLink} target="blank">
            <div className={styles.icon}>
              <img loading="lazy" src={whatsappIcon} />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Whatsapp;
