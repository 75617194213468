import styles from "./Header.module.css";
import logoIcon from "../../../Assests/Icons/blueLogoAidem.svg";
import menuIcon from "../../../Assests/Icons/MenuIcon.svg";
import instagram from "../../../Assests/Icons/Instagram.svg";
import whatsapp from "../../../Assests/Icons/WhatsApp.svg";
import tele from "../../../Assests/Icons/iconTelegram.svg";
import tiktok from "../../../Assests/Icons/TikTok.svg";
import {
  whatsappLink,
  telegramLink,
  instagramLink,
  tiktokLink,
} from "../../../Utils/Constants/Constants.js";
import { useState } from "react";
import { useEffect } from "react";

export const Header = ({ setMenuOpen }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.container} id="main">
      <div
        className={`${styles.wrapper} ${
          scrollPosition > 0 ? styles.headerShadow : ""
        }`}
      >
        <div className={styles.menu}>
          <img
            className={styles.menuIcon}
            src={menuIcon}
            alt="menuIcon"
            onClick={() => setMenuOpen(true)}
          />
        </div>
        <div>
          <img src={logoIcon} className={styles.mainIcon} alt="mainIcon" />
        </div>
        <div className={styles.socialSites}>
          <a href={whatsappLink} target="blank">
            <img className={styles.socialSite} src={whatsapp} alt="whatsapp" />
          </a>
          <a href="https://t.me/+HqE6zb1MXDQ2YzRi" target="blank">
            <img className={styles.socialSite} src={tele} alt="telegram" />
          </a>
          <a href={instagramLink} target="blank">
            <img
              className={styles.socialSite}
              src={instagram}
              alt="instagram"
            />
          </a>
          <a href={tiktokLink}>
            <img className={styles.socialSite} src={tiktok} alt="tiktok" />
          </a>
        </div>
      </div>
    </div>
  );
};
