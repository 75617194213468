import styles from "./MarketPlaces.module.css";
import MarketPlacesPhoto from "../../../Assests/Image/marketplacesImg.jpg";

const MarketPlaces = () => {
  return (
    <div className={styles.container} id="marketplaces">
      <p className={styles.descriptionDelivery}>
        Заказать любую продукцию из Кыргызстана оптом можно находясь в любом
        городе СНГ.
      </p>
      <div className={styles.content}>
        <div className={styles.imageBox}>
          <img
            src={MarketPlacesPhoto}
            className={styles.image}
            alt="marketPlaces"
          />
        </div>
        <div className={styles.text}>
          <p className={styles.title}>
            Мы специализируемся на пошиве изделий для маркетплейсов и магазинов.
          </p>
          <p className={styles.description}>
            Наши партнеры - это крупные, а также начинающие селлеры
            маркетплейсов, оптовики, владельцы магазинов и шоурумов. У нас самые
            выгодные условия, которые учитывают все ваши пожелания, а также
            гарантирующее качество и безопасное сотрудничество.
          </p>
          <p className={styles.description}>
            Люди все чаще предпочитают совершать покупки не выходя из дома. Это
            означает, что рынок онлайн - продаж будет только расти и мы готовы
            помочь вам в Вашем бизнесе. Мы знаем как!
          </p>
        </div>
      </div>
    </div>
  );
};

export default MarketPlaces;
