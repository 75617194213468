import styles from "./VideoContent.module.css";

const VideoContent = () => {
  return (
    <div className={styles.container} id="about-us">
      <div className={styles.wrapper}>
        <div className={styles.video}>
          <iframe
            height="400"
            src="https://www.youtube.com/embed/UPUDTgYCnz4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className={styles.text}>
          <h1>Швейное производство женский одежды AIDEM</h1>
          <p>
            Почему мы? Наша команда AIDEM заинтересована в том чтобы выводить
            Вас в топ своим безупречным качеством, к которому мы подходим с
            особым вниманием и любовью. В нашей команде работают только
            профессионалы своего дела, каждый вкладывает частичку своей
            души,чтобы вы остались довольны !
          </p>
        </div>
      </div>
    </div>
  );
};

export default VideoContent;
