import { useState } from "react";
import styles from "./CustomInput.module.css";

const CustomInput = ({ placeholder, value, onChange, blur, setBlur }) => {
  const inputClassName = blur && value === "" ? styles.error : styles.input;
  return (
    <div>
      <input
        className={inputClassName}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onBlur={() => {
          setBlur(true);
        }}
      />
    </div>
  );
};

export default CustomInput;
