import styles from "./Clothes.module.css";

const Clothes = ({ openModal }) => {
  const photos = [
    {
      id: 1,
      title: "Юбка шелковая на резинке, Длина: миди",
      className: "palto1",
    },
    {
      id: 2,
      title: "Юбка гипюровая на резинке и подкладе мини, Длина: миди",
      className: "palto2",
    },
    {
      id: 3,
      title: "Платье Лапша на завязках Длина: миди",
      className: "palto3",
    },
    {
      id: 4,
      title: "Брюки Гипюровые длинные, на резинке и подкладе мини",
      className: "palto4",
    },
  ];
  return (
    <div className={styles.container} id="clothes">
      <p className={styles.layotTitile}>Повседневная одежда на заказ</p>
      <div className={styles.allPhotos}>
        <div className={styles.bigPhoto}></div>
        <div className={styles.photos}>
          {photos.map((el) => {
            return (
              <div className={styles.cards} key={el.id}>
                <div className={styles[el.className]}></div>
                <p className={styles.title}>{el.title}</p>
              </div>
            );
          })}
        </div>
      </div>
      <button className={styles.button} onClick={openModal}>
        Оставить заявку
      </button>
    </div>
  );
};

export default Clothes;
