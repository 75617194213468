import styles from "./Footer.module.css";
import logoIcon from "../../../Assests/Icons/blueLogoAidem.svg";
import whatsapp from "../../../Assests/Icons/WhatsAppFooter.svg";
import telegram from "../../../Assests/Icons/TegegramFooter.svg";
import instagram from "../../../Assests/Icons/instagramFooter.svg";
import tiktok from "../../../Assests/Icons/TiktokFooter.svg";
import {
  whatsappLink,
  telegramLink,
  instagramLink,
  tiktokLink,
} from "../../../Utils/Constants/Constants";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 800,
      behavior: "smooth",
    });
  };
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.socialSites}>
          <div className={styles.logoBox}>
            <img className={styles.logoIcon} src={logoIcon} alt="logoIcon" />
          </div>
          <div className={styles.socialSiteBox}>
            <a href={whatsappLink}>
              <img
                className={styles.socialSite}
                src={whatsapp}
                alt="whatsapp"
              />
            </a>
            <p>
              <a href={whatsappLink}>+996 999 007 528</a>
            </p>
          </div>
          <div className={styles.socialSiteBox}>
            <a href={telegramLink}>
              <img
                className={styles.socialSite}
                src={telegram}
                alt="whatsapp"
              />
            </a>
            <a href={telegramLink} target="blank">
              <p>@aidem_pro</p>
            </a>
          </div>
          <div className={styles.socialSiteBox}>
            <a href={instagramLink} target="blank">
              <img
                className={styles.socialSite}
                src={instagram}
                alt="whatsapp"
              />
            </a>
            <a href={instagramLink} target="blank">
              <p>@aidem_pro</p>
            </a>
          </div>
          <div className={styles.socialSiteBox}>
            <a href={tiktokLink}>
              <img className={styles.socialSite} src={tiktok} alt="whatsapp" />
            </a>
            <a href={tiktokLink} target="blank">
              <p>@aidem_pro</p>
            </a>
          </div>
        </div>
        <div className={styles.information}>
          <p className={styles.title}>Информация</p>
          <p className={styles.aboutUs} onClick={scrollToTop}>
            О нас
          </p>
          <p>Доставка и Оплата</p>
        </div>
        <div className={styles.contact}>
          <p className={styles.title}>Контакты</p>
          <p>
            <a href="https://go.2gis.com/104t2">
              Швейное производство "AIDEM" Кыргызская Республика, г. Бишкек,
              проспект Чуй 271
            </a>
          </p>
          <p>+996 999 007 528</p>
          <p>aidembrand@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
