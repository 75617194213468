import styles from "./CustomTextArea.module.css";

const CustomTextArea = ({ placeholder, value, onChange, blur, setBlur }) => {
  const inputClassName = blur && value === "" ? styles.error : styles.input;

  return (
    <textarea
      className={inputClassName}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      onBlur={() => {
        setBlur(true);
      }}
    ></textarea>
  );
};

export default CustomTextArea;
