import React from "react";
import styles from "./InstagramContent.module.css";
import { InstagramEmbed } from "react-social-media-embed";

const instagramPosts = [
  { id: 2, url: "https://www.instagram.com/p/C1zUQybN2Gp/" },
  { id: 4, url: "https://www.instagram.com/p/C1CtxaOLjKv/" },
  { id: 5, url: "https://www.instagram.com/p/Cz4UGJvS62v/" },
];

const InstagramPostEmbed = () => {
  return (
    <div className={styles.container} id="instagram">
      <div className={styles.wrapper}>
        {instagramPosts.map((el) => {
          return (
            <InstagramEmbed
              key={el.id}
              className={styles.InstagramEmbed}
              url={el.url}
              width={328}
              id={el.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default InstagramPostEmbed;
