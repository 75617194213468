import React, { useState, useEffect } from "react";
import styles from "./Corousel.module.css"; // Убедитесь, что путь к CSS-файлу верный
import slidePhoto from "../../../../src/Assests/Image/slide1.png";
import slidePhoto2 from "../../../../src/Assests/Image/slide2.png";
import slidePhoto3 from "../../../../src/Assests/Image/slide3.png";
import slidePhoto4 from "../../../../src/Assests/Image/slide4.png";
import { instagramLink } from "../../../Utils/Constants/Constants";

const carouselData = [
  {
    id: 0,
    url: slidePhoto,
    title:
      "ПОШИВ ЖЕНСКОЙ ОДЕЖДЫ ОПТОМ ДЛЯ МАРКЕТПЛЕЙСОВ И МАГАЗИНОВ С МАРЖИНАЛЬНОСТЬЮ Х3 - Х6 НАПРЯМУЮ ОТ ПРОИЗВОДИТЕЛЯ",
  },
  {
    id: 1,
    url: slidePhoto,
    title: "Цель выводить Вас в топ качеством пошива",
  },
  {
    id: 2,
    url: slidePhoto3,
    title: "Пошив и упаковка по стандартам маркетплейсов",
  },
  {
    id: 3,
    url: slidePhoto4,
    title: "Индивидуальный подход к каждому заказу",
  },
];

const Carousel = ({ openModal }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
  //   }, 6000); // 5000ms = 5 секунд между переходами

  //   return () => clearInterval(interval);
  // }, []);

  const getSlideStyle = (index) => {
    let style = {
      backgroundImage: `url(${carouselData[index].url})`,
      transform: "translateX(100%)",
      opacity: 0,
    };

    if (index === currentIndex) {
      style.transform = "translateX(0)";
      style.opacity = 1;
    } else if (
      index ===
      (currentIndex - 1 + carouselData.length) % carouselData.length
    ) {
      style.transform = "translateX(-100%)";
    }

    return style;
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {carouselData.map((slide, index) => (
          <div
            key={slide.id}
            className={styles.slide}
            style={getSlideStyle(index)}
          >
            <div className={styles.overlay}>
              <div className={styles.content}>
                <p className={styles.text}>{slide.title}</p>

                <button className={styles.button} onClick={openModal}>
                  Получить оптовый прайс
                </button>
                <p className={styles.corouselText}>
                  Под ключ • От 300 единиц • Работа по договору • Честный Знак
                </p>
                {/* <div className={styles.identifactors}>
            <div className={styles.content}>
              <p className={styles.text}>{slide.title}</p>

              <a href={instagramLink} target="blank">
                <button className={styles.button}>Перейти в католог</button>
              </a>
              <p className={styles.corouselText}>
                Под ключ • От 300 единиц • Работа по договору • Честный Знак
              </p>
              {/* <div className={styles.identifactors}>
                {carouselData.map((el, localIndex) => {
                  return (
                    <div
                      onClick={() => {
                        setCurrentIndex(localIndex);
                      }}
                      className={
                        index === localIndex
                          ? styles.choosedIdentifactor
                          : styles.identifactor
                      }
                      key={el.id}
                    ></div>
                  );
                })}
              </div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
