import React, { useState } from "react";
import "./App.css";
import AboutUs from "./Components/UI/AboutUs/AboutUs";
import Cards from "./Components/UI/Cards/Cards";
import Clothes from "./Components/UI/Clothes/Clothes";
import Corousel from "./Components/UI/Corousel/Corousel";
import Footer from "./Components/UI/Footer/Footer";
import { Header } from "./Components/UI/Header/Header";
import InstagramPostEmbed from "./Components/UI/InstagramContent/InstagramContent";
import MarketPlaces from "./Components/UI/MarketPlaces/MarketPlaces";
import MenuContainer from "./Components/UI/MenuContainer/MenuContainer";
import Modal from "./Components/UI/Modal/Modal";
import VideoContent from "./Components/UI/VideoContent/VideoContent";
import Whatsapp from "./Components/UI/Whatsapp/Whatsapp";

function App() {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  console.log(isMenuOpen);
  return (
    <div className="App">
      <Header setMenuOpen={setMenuOpen} />
      <Corousel openModal={openModal} />
      <Clothes openModal={openModal} />
      <VideoContent />
      <AboutUs openModal={openModal} />
      <InstagramPostEmbed />
      <Cards />
      <MarketPlaces />
      <Footer />
      <Whatsapp />
      <Modal isOpen={modalOpen} onClose={closeModal} />
      <MenuContainer isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
    </div>
  );
}

export default App;
