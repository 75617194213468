import styles from "./Cards.module.css";
import cardIcon1 from "../../../Assests/Icons/cardIcon2.svg";
import cardIcon2 from "../../../Assests/Icons/cardIcon2.svg";
import cardIcon3 from "../../../Assests/Icons/cardIcon3.svg";
import cardIcon4 from "../../../Assests/Icons/cardIcon4.svg";
import cardIcon5 from "../../../Assests/Icons/cardIcon5.svg";
import cardIcon6 from "../../../Assests/Icons/cardIcon6.svg";
import logoIcon from "../../../Assests/Icons/blueLogoAidem.svg";

const Cards = () => {
  const cardsData = [
    {
      id: 1,
      title: "Лучшее соотношение цены и качества",
      description:
        "Высокое качество, доступные цены: идеальное сочетание в нашем швейном производстве. Закажите прямо сейчас",
      icon: cardIcon1,
      cardClass: styles.card1,
    },
    {
      id: 2,
      title: "Индивидульный подход к каждому заказу",
      description:
        "Заботливо создаем каждую деталь: наше швейное производство гарантирует индивидуальный подход к вашему заказу",
      icon: cardIcon2,
      cardClass: styles.card2,
    },
    {
      id: 3,
      title: "Идеальный старт для продавцов",
      description:
        "Минимальный заказ от 30 000р отличная возможность для селлеров заказать пробную партию",
      icon: cardIcon3,
      cardClass: styles.card3,
    },
    {
      id: 4,
      title: "Брендирование товара",
      description:
        "Брендирование товара бирки, лейби полностью под вашим логотипом",
      icon: cardIcon4,
      cardClass: styles.card4,
    },
    {
      id: 5,
      title: "Легкая логистика: быстро и удобно",
      description:
        "Оптимизированная логистика для мгновенных поставок прямо в ваш город. Быстро, удобно, беззаботно. Надежность в каждой доставке",
      icon: cardIcon5,
      cardClass: styles.card5,
    },
    {
      id: 6,
      title: "Быстрое масштабирование вашего бизнеса",
      description:
        "Вы быстро масштабируете свой бизнес, так как спрос на наши изделия всегда остается большим",
      icon: cardIcon6,
      cardClass: styles.card6,
    },
  ];

  return (
    <div className={styles.container} id="advantage">
      <div className={styles.wrapper}>
        <div className={styles.bigIcons}>
          <img src={logoIcon} className={styles.logoIcon} alt="logoIcon" />
        </div>
        <p className={styles.layotTitle}>
          ПОЧЕМУ ПАРТНЕРСТВО С НАШЕЙ КОМПАНИЕЙ ВЫГОДНО?
        </p>
        <div className={styles.cards}>
          {cardsData.map((el) => {
            return (
              <div key={el.id} className={el.cardClass}>
                <div className={styles.icon}>
                  <span
                    className={styles.cardIcon}
                    src={el.blueIcon}
                    alt="icon"
                  />
                </div>
                <p className={styles.title}>{el.title}</p>
                <p className={styles.description}>{el.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Cards;
